import * as React from "react";
import "./Teachers.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCollection } from "../../helpers/utils";
import { Teacher } from "../../models/teacher";

// Components
import TeacherCard from "../card/teacher/TeacherCard";

// Mantine
import { Loader } from "@mantine/core";

export default function Teachers() {
  // Save in context and group contexts
  const [teachers, setTeachers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (teachers.length === 0) {
      fetchCollection("teachers", setTeachers);
    }
  }, [teachers.length, setTeachers]);

  return (
    <>
      <div className="courses-banner">
        <h1 className="page-title">Browse Teachers</h1>
      </div>

      <div className="cards-container">
        {teachers.length !== 0 ? (
          teachers
            .filter((teacher) => teacher.type !== "test") // I should use a differet db to test
            .map((teacher: Teacher) => (
              <div
                className="card-container"
                key={teacher.id}
                onClick={() =>
                  navigate(`/teachers/${teacher.id}`, { state: teacher })
                }
              >
                <TeacherCard teacher={teacher}></TeacherCard>
              </div>
            ))
        ) : (
          <Loader variant="dots" size="lg" className="loader-spacing" />
        )}
      </div>
    </>
  );
}

import * as React from "react";
import { useState } from "react";
import { ComponentStatus, EmailPassword } from "../../models/shared";

// Firebase
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

// Mantine
import { Text, TextInput, Button, Group, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";

export default function EmailSignIn({ type = "login" }) {
  const [componentStatus, setComponentStatus] =
    useState<ComponentStatus>("ready");
  const [error, setError] = useState("");

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        /^([a-zA-Z0-9@*#]{6,15})$/.test(value)
          ? null
          : "At least 6 characters, letters or numbers",
    },
  });

  const handleSubmit = async (values) => {
    const auth = getAuth();

    try {
      setComponentStatus("loading");
      if (type === "login") {
        await signInWithEmailAndPassword(auth, values.email, values.password);
        // Check for Custom Claims once Next.js OK
        // .then((result) => {
        //   const details = getAdditionalUserInfo(result);
        //   console.log("details", details);

        //   if (details.isNewUser) {
        //     console.log("New User"); // true
        //   } else {
        //     console.log("Old User"); // false
        //   }
        // });
      } else {
        await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
      }
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          setError("Wrong email or password");
          break;
        case "auth/user-not-found":
          setError("User not found");
          break;
        default:
          setError("Sorry, an error occured");
      }

      setComponentStatus("ready");
    }
  };

  return componentStatus === "loading" ? (
    <Loader size="lg" />
  ) : (
    <form
      onSubmit={form.onSubmit((values: EmailPassword) => handleSubmit(values))}
      style={{
        width: "240px",
      }}
    >
      <TextInput
        withAsterisk
        label="Email"
        placeholder="your@email.com"
        {...form.getInputProps("email")}
      />
      <TextInput
        type="password"
        withAsterisk
        label="Password"
        placeholder="Password"
        autoComplete="on"
        {...form.getInputProps("password")}
      />
      {error && <Text> {error}</Text>}
      <Group position="right" mt="md">
        <Button type="submit">
          {type === "signup" ? "Sign Up" : "Sign In"}
        </Button>
      </Group>
    </form>
  );
}

import * as React from "react";
import "./TeacherForm.css";
import { TeacherPersonalInfo } from "../../models/teacher";
import { UserAuth } from "../../states/AuthContext";
import { ComponentStatus } from "../../models/shared";
import { useState } from "react";

// Mantine
import { Box, Button, TextInput, Group, Textarea, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

export function TeacherForm({ teacherData }) {
  const { user } = UserAuth();
  let uid = user?.uid;
  const [status, setStatus] = useState<ComponentStatus>("ready");

  const handleSubmit = () => {
    const formValues: TeacherPersonalInfo = form.values;
    saveData({ formValues });
  };

  const saveData = async (props: { formValues: TeacherPersonalInfo }) => {
    setStatus("loading");
    let teacherPersonalInfo: TeacherPersonalInfo = props.formValues;
    // Should happen in the backend, also check if new user or user is editing
    // const teacherData: Teacher = {
    //   ...teacherPersonalInfo,
    //   rating,
    //   classesIdByTeacher: [],
    //   classesCompleted,
    //   reviewsInTotal,
    //   reviews: [],
    //   joinedInYear: new Date().getFullYear(),
    //   rating: 0,
    // };

    await setDoc(doc(db, "teachers", `${uid}`), teacherPersonalInfo)
      .then(() => {
        setStatus("ready");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const form = useForm({
    initialValues: {
      name: teacherData?.name || "",
      lastname: teacherData?.lastname || "",
      nickname: teacherData?.nickname || "",
      outline: teacherData?.outline || "",
      aboutMe: teacherData?.aboutMe || "",
      livesIn: teacherData?.livesIn || "",
      longDescription: teacherData?.longDescription || "",
      pictureStorageURI: teacherData?.pictureStorageURI || "",
      // socials: [],
    },

    validate: {
      name: (value) => (value.length > 1 ? null : "Please, add your name"),
      lastname: (value) =>
        value.length > 1 ? null : "Please, add your lastname",
      outline: (value) =>
        value.length < 55 ? null : "Only 55 characters allowed",
      aboutMe: (value) =>
        value.length < 250 ? null : "Only 250 characters allowed",
    },
  });

  return (
    <Box mx="auto" mt="lg">
      {status === "ready" ? (
        <form onSubmit={form.onSubmit(handleSubmit)} className="form">
          <TextInput
            withAsterisk
            label="Name"
            placeholder="Your First Name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Lastname"
            placeholder="Your Lastname"
            {...form.getInputProps("lastname")}
          />
          {/* <TextInput
          label="Nickname"
          placeholder="Your Nickname"
          {...form.getInputProps("nickname")}
        /> */}
          <TextInput
            label="Your Professional Title"
            placeholder="e.g. Digital Illustrator"
            {...form.getInputProps("outline")}
          />
          <Textarea
            label="About Me"
            minRows="3"
            placeholder="A Short Description Of Yourself"
            {...form.getInputProps("aboutMe")}
          />
          <TextInput
            label="I Live In"
            placeholder="Country"
            {...form.getInputProps("livesIn")}
          />
          <Textarea
            label="Description"
            minRows="3"
            placeholder="A Long Description Of Yourself"
            {...form.getInputProps("longDescription")}
          />
          {/* Add list of socials */}

          <Group position="right" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      ) : (
        <div className="cards-container">
          <Loader mx="auto" size="lg" />
        </div>
      )}
    </Box>
  );
}

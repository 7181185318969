import * as React from "react";
import "./Courses.css";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCollection } from "../../helpers/utils";
import { CoursesContext } from "../../states/CoursesContext";
import { Course } from "../../models/course";

// Components
import { CourseCard } from "../card/course/CourseCard";

// Mantine
import { Loader } from "@mantine/core";

export default function Courses() {
  const { courses, setCourses } = useContext(CoursesContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (courses.length === 0) {
      fetchCollection("classes", setCourses);
    }
  }, [courses.length, setCourses]);

  return (
    <>
      <div className="courses-banner">
        <h1 className="page-title">Browse Classes</h1>
      </div>

      <div className="cards-container">
        {courses.length !== 0 ? (
          courses.map((course: Course) => (
            <div
              className="card-container"
              key={course.id}
              onClick={() =>
                navigate(`/courses/${course.id}`, { state: course })
              }
            >
              <CourseCard {...course} />
            </div>
          ))
        ) : (
          <Loader variant="dots" size="lg" className="loader-spacing" />
        )}
      </div>
    </>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import "./Profile.css";
import { TeacherForm } from "../teachers/TeacherForm";
import { TeacherPersonalInfo } from "../../models/teacher";
import { UserAuth } from "../../states/AuthContext";

// Firebase
import { fetchDocFromCollection } from "../../helpers/utils";
import { Button, Loader, Space } from "@mantine/core";
import { ComponentStatus } from "../../models/shared";
import { LearnerForm } from "../learners/LearnerForm";
import { LearnerPersonalInfo } from "../../models/learner";

const teacherPersonalInfo: TeacherPersonalInfo = {
  name: "",
  lastname: "",
  nickname: "",
  outline: "",
  aboutMe: "",
  livesIn: "",
  longDescription: "",
  pictureStorageURI: "",
  socials: [],
};

const learnerPersonalInfo: LearnerPersonalInfo = {
  name: "",
  lastname: "",
  outline: "",
  livesIn: "",
  pictureStorageURI: "",
};

export default function Profile() {
  // Lame. To be fixed with Custom Claims. Use Next.js as backend
  const [teacherData, setTeacherData] = useState(teacherPersonalInfo);
  const [learnerData, setLearnerData] = useState(learnerPersonalInfo);
  const [isTeacher, setIsTeacher] = useState(true);
  const { user } = UserAuth();
  const [status, setStatus] = useState<ComponentStatus>("ready");

  useEffect(() => {
    setStatus("loading");
    if (user?.uid) {
      // Lame. To be fixed with Custom Claims. Use Next.js as backend
      const fetchTeacher = fetchDocFromCollection(
        user.uid,
        "teachers",
        setTeacherData
      );
      const fetchLearner = fetchDocFromCollection(
        user.uid,
        "learners",
        setLearnerData
      );
      Promise.all([fetchTeacher, fetchLearner]).then((data) => {
        const fetchedTeacherData = data[0];
        fetchedTeacherData?.name ? setIsTeacher(true) : setIsTeacher(false);
        setStatus("ready");
      });
    }
  }, [user]);

  return (
    <>
      <div className="courses-banner">
        <h1 className="page-title">Your Profile</h1>
      </div>
      <div className="cards-container">
        {user?.uid ? (
          <div>
            <div className="form-choice-container">
              <div>I want to: </div>
              <Space h="md" />
              <div className="form-choice-buttons-container">
                <Button
                  variant={isTeacher ? "filled" : "outline"}
                  uppercase
                  onClick={() => setIsTeacher(true)}
                >
                  Teach
                </Button>
                <Button
                  variant={isTeacher ? "outline" : "filled"}
                  uppercase
                  onClick={() => setIsTeacher(false)}
                >
                  Learn
                </Button>
              </div>
            </div>
            {status === "ready" ? (
              <>
                {isTeacher ? (
                  <TeacherForm teacherData={teacherData} />
                ) : (
                  <LearnerForm learnerData={learnerData} />
                )}
              </>
            ) : (
              <div className="cards-container">
                <Loader mx="auto" size="lg" />
              </div>
            )}
          </div>
        ) : (
          <>Please, log in to see your profile</>
        )}
      </div>
    </>
  );
}

import * as React from "react";
import { useState } from "react";
import GoogleSignIn from "./GoogleSignIn";
import EmailSignIn from "./EmailSignIn";
import { UserAuth } from "../../states/AuthContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// Mantine
import { Box, Space, Button, Title } from "@mantine/core";

const SwitchSignInSignUp = ({ switchTo = "signup" }) => {
  const navigate = useNavigate();

  return (
    <p>
      {switchTo === "signup"
        ? "Don't have an account?"
        : "Already have an account?"}
      &nbsp;
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => navigate(`/${switchTo}`)}
      >
        {switchTo === "signup" ? "Sign up" : "Sign In"}
      </span>
    </p>
  );
};

export const SignedIn = () => {
  return (
    <>
      <Title order={2}>Successfully signed in</Title>
      <Space h="xl" />
      <Button radius="xl" size="md">
        <NavLink to="/" className="route-link">
          Home
        </NavLink>
      </Button>
    </>
  );
};

export default function Login() {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const { user } = UserAuth();

  const url = useLocation().pathname;
  const loginOrSignup = url.substring(url.lastIndexOf("/") + 1);

  return (
    <Box
      sx={{
        maxWidth: 400,
        paddingTop: "4em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      mx="auto"
    >
      {user ? (
        <SignedIn />
      ) : (
        <>
          <Title order={2}>
            {loginOrSignup === "login" ? "Sign in" : "Sign up"}
          </Title>
          <Space h="xl" />
          <GoogleSignIn />
          <Space h="xl" />
          {showEmailForm ? (
            <EmailSignIn type={loginOrSignup} />
          ) : (
            <Button
              onClick={() => setShowEmailForm(true)}
              variant="outline"
              style={{ width: "240px", textTransform: "capitalize" }}
            >
              {loginOrSignup}&nbsp;with email
            </Button>
          )}

          <Space h="xl" />
          <SwitchSignInSignUp
            switchTo={loginOrSignup !== "signup" ? "signup" : "login"}
          />
        </>
      )}
    </Box>
  );
}

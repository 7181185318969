// Firebase
import { db, storage } from "../firebase-config";
import {
  doc,
  DocumentData,
  getDoc,
  collection,
  getDocs,
} from "@firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";

export const fetchFromStorage = (
  gsURI: string,
  stateHook: { (value: any): void; (arg0: DocumentData): void }
) => {
  // Create a reference from a Google Cloud Storage URI
  const gsReference = ref(storage, gsURI);

  // Get the download URL
  getDownloadURL(gsReference)
    .then((url) => {
      stateHook(url);
      // return url;
    })
    .catch((error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/object-not-found":
          // File doesn't exist
          break;
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;
        case "storage/unknown":
          // Unknown error occurred, inspect the server response
          break;
      }
    });
};

export const fetchDocFromCollection = async (
  docId: string,
  collectionName: string,
  stateHook: { (value: any): void; (arg0: DocumentData): void }
) => {
  const docRef = doc(db, collectionName, `${docId}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    stateHook(docSnap.data());
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    // console.log("No such document!");
    stateHook(undefined);
    return undefined;
  }
};

export const fetchCollection = async (
  collectionName: string,
  stateHook: { (value: any): void; (arg0: DocumentData): void }
) => {
  const coursesCollectionRef = collection(db, collectionName); // rename collection in DB
  const data = await getDocs(coursesCollectionRef);

  stateHook(
    data.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    })
  );
};

export const showDateTime = (timestamp): string => {
  if (!timestamp) {
    return "In Preparation";
  }
  const date = new Date(timestamp.seconds * 1000).toDateString();
  const time = new Date(timestamp.seconds * 1000).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return `${date} at ${time}`;
};

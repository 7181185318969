import {
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  Menu,
  ActionIcon,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCode,
  IconGrowth,
  IconCoin,
  IconChevronDown,
  IconLanguage,
  IconUser,
  IconLogout,
  IconPlus,
} from "@tabler/icons";
import * as React from "react";
import "./Header.css";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { UserAuth } from "../../states/AuthContext";

const vategories = [
  {
    icon: IconCode,
    title: "Tech",
    description: "Web dev, data analysis, and programming",
  },
  {
    icon: IconCoin,
    title: "Business",
    description: "Corporate, SME, startup and entrepreneurship",
  },
  {
    icon: IconLanguage,
    title: "Languages",
    description: "Learn human languages from other humans",
  },
  {
    icon: IconGrowth,
    title: "Arts",
    description: "Art is bliss for the soul",
  },
];

export function HeaderMegaMenu() {
  const { user, logout } = UserAuth();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const navigate = useNavigate();

  const links = vategories.map((item) => (
    <UnstyledButton className="subLink" key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={22} />
        </ThemeIcon>
        <div>
          <Text size="sm" weight={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Link to="/" className="logo">
            LERNAO
          </Link>
          <Group sx={{ height: "100%" }} spacing={0} className="hiddenOnMobile">
            <NavLink to="/" className="link">
              Home
            </NavLink>
            <NavLink to="/courses" className="link">
              Classes
            </NavLink>
            <NavLink to="/teachers" className="link">
              Teachers
            </NavLink>
          </Group>
          {user ? (
            <Group className="hiddenOnMobile">
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <ActionIcon size="lg" radius="xl" variant="outline">
                    <IconUser size={20} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconUser size={16} />}
                    onClick={() => navigate(`/profile`)}
                  >
                    Profile
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconPlus size={16} />}
                    onClick={() => navigate(`/create-course`)}
                  >
                    Create Courses
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconLogout size={16} />}
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          ) : (
            <Group className="hiddenOnMobile">
              <Button variant="default" onClick={() => navigate(`/login`)}>
                Log in
              </Button>
              <Button onClick={() => navigate(`/signup`)}>Sign up</Button>
            </Group>
          )}

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className="hiddenOnDesktop"
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Menu"
        className="hiddenOnDesktop"
        zIndex={1000000}
      >
        <Divider my="sm" />

        <NavLink to="/" className="link" onClick={toggleDrawer}>
          Home
        </NavLink>
        <NavLink to="/courses" className="link" onClick={toggleDrawer}>
          Classes
        </NavLink>
        <NavLink to="/teachers" className="link" onClick={toggleDrawer}>
          Teachers
        </NavLink>
        <UnstyledButton className="link" onClick={toggleLinks}>
          <Center inline>
            <Box component="span" mr={5}>
              Popular Topics
            </Box>
            <IconChevronDown size={16} />
          </Center>
        </UnstyledButton>
        {user ? (
          <>
            <NavLink to="/profile" className="link" onClick={toggleDrawer}>
              Profile
            </NavLink>
            <NavLink to="/create-course" className="link" onClick={toggleDrawer}>
              Create Course
            </NavLink>
            <NavLink to="/" className="link" onClick={handleLogout}>
              Logout
            </NavLink>
          </>
        ) : (
          <NavLink to="/login" className="link" onClick={toggleDrawer}>
            Login or Signup
          </NavLink>
        )}
        <Collapse in={linksOpened} onClick={toggleDrawer}>
          {links}
        </Collapse>
      </Drawer>
    </Box>
  );
}

import * as React from "react";
import GoogleButton from "react-google-button";

import { getAuth, signInWithPopup } from "firebase/auth";
import { provider } from "../../firebase-config";

export default function GoogleSignIn() {
  const auth = getAuth();

  const handleGoogleButtonClick = async () => {
    await signInWithPopup(auth, provider);
  };

  return <GoogleButton onClick={handleGoogleButtonClick} />;
}

import * as React from "react";
import "./LearnerForm.css";

// Mantine
import { Box, Button, TextInput, Group, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { UserAuth } from "../../states/AuthContext";
import { LearnerPersonalInfo } from "../../models/learner";
import { ComponentStatus } from "../../models/shared";
import { useState } from "react";

export function LearnerForm({ learnerData }) {
  const { user } = UserAuth();
  let uid = user?.uid;
  const [status, setStatus] = useState<ComponentStatus>("ready");

  const handleSubmit = () => {
    const formValues: LearnerPersonalInfo = form.values;
    saveData({ formValues });
  };

  const saveData = async (props: { formValues: LearnerPersonalInfo }) => {
    setStatus("loading");
    let learnerPersonalInfo: LearnerPersonalInfo = props.formValues;
    // Should happen in the backend, also check if new user or user is editing
    // const learnerData: Learner = {
    //   ...learnerPersonalInfo,
    //   classesCompleted,
    //   joinedInYear: new Date().getFullYear(),
    // };

    await setDoc(doc(db, "learners", `${uid}`), learnerPersonalInfo)
      .then(() => {
        setStatus("ready");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const form = useForm({
    initialValues: {
      name: learnerData?.name || "",
      lastname: learnerData?.lastname || "",
      outline: learnerData?.outline || "",
      livesIn: learnerData?.livesIn || "",
      pictureStorageURI: learnerData?.pictureStorageURI || "",
    },

    validate: {
      name: (value) => (value.length > 1 ? null : "Please, add your name"),
      outline: (value) =>
        value.length < 55 ? null : "Only 55 characters allowed",
    },
  });

  return (
    <Box mx="auto" mt="lg">
      {status === "ready" ? (
        <form onSubmit={form.onSubmit(handleSubmit)} className="form">
          <TextInput
            withAsterisk
            label="Name"
            placeholder="Your First Name"
            {...form.getInputProps("name")}
          />
          <TextInput
            label="Lastname"
            placeholder="Your Lastname"
            {...form.getInputProps("lastname")}
          />
          <TextInput
            label="Your Professional Title, Job, or Aspiration"
            placeholder="e.g. Digital Illustrator"
            {...form.getInputProps("outline")}
          />
          <TextInput
            label="I Live In"
            placeholder="Country"
            {...form.getInputProps("livesIn")}
          />

          <Group position="right" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      ) : (
        <div className="cards-container">
          <Loader mx="auto" size="lg" />
        </div>
      )}
    </Box>
  );
}

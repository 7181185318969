import * as React from "react";
import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CoursesContext } from "./states/CoursesContext";
import { AuthContextProvider } from "./states/AuthContext";
import { MantineProvider } from "@mantine/core";

// Components
import { HeaderMegaMenu } from "./components/header/Header";
import Main from "./components/main/Main";
import Courses from "./components/courses/Courses";
import { CoursePage } from "./components/coursePage/CoursePage";
import Login from "./components/login/Login";
import { TeacherPage } from "./components/teachers/TeacherPage";
import Teachers from "./components/teachers/Teachers";
import Profile from "./components/toRefactor/Profile";
import CreateCoursePage from "./components/toRefactor/CreateCoursePage";

export default function App() {
  const [courses, setCourses] = useState([]);

  return (
    <AuthContextProvider>
      <CoursesContext.Provider value={{ courses, setCourses }}>
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <Router>
            <HeaderMegaMenu />
            <div className="container">
              <Routes>
                <Route path="/about" />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/:courseId" element={<CoursePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/create-course" element={<CreateCoursePage />} />
                <Route path="/signup" element={<Login />} />
                <Route path="/teachers" element={<Teachers />} />
                <Route path="/teachers/:teacherId" element={<TeacherPage />} />
                <Route path="/" element={<Main />} />
                <Route path="/*" element={<Main />} />
              </Routes>
            </div>
          </Router>
        </MantineProvider>
      </CoursesContext.Provider>
    </AuthContextProvider>
  );
}

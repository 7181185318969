import * as React from "react";
import { useState, useEffect } from "react";

import { TeacherPersonalInfo } from "../../models/teacher";
import { UserAuth } from "../../states/AuthContext";
import { CreateCourseForm } from "../teachers/CreateCourseForm";
import { ComponentStatus } from "../../models/shared";

// Firebase
import { fetchDocFromCollection } from "../../helpers/utils";
import { Loader } from "@mantine/core";

const teacherPersonalInfo: TeacherPersonalInfo = {
  name: "",
  lastname: "",
  nickname: "",
  outline: "",
  aboutMe: "",
  livesIn: "",
  longDescription: "",
  pictureStorageURI: "",
  socials: [],
};

export default function CreateCoursePage() { 
  const [teacherData, setTeacherData] = useState(teacherPersonalInfo);
  const { user } = UserAuth();
  const [status, setStatus] = useState<ComponentStatus>("loading");

  useEffect(() => {
    if (user?.uid) {
      fetchDocFromCollection(user.uid, "teachers", setTeacherData).then(() => {
        setStatus("ready");
      });
    }
  }, [user]);
  return (
    <>
    <div className="courses-banner">
      <h1 className="page-title">Create Your Courses</h1>
    </div>

    <div className="cards-container">
      {user?.uid ? (
        <>
          {status === "ready" ? (
            <CreateCourseForm teacherData={teacherData} />
          ) : (
            <Loader size="lg" />
          )}
        </>
      ) : (
        <>Please, log in to see your profile</>
      )}
    </div>
  </>
  )
}
import * as React from "react";
import "./TeacherPage.css";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchDocFromCollection, fetchFromStorage } from "../../helpers/utils";
import { getAuth } from "firebase/auth";

// Mantine
import {
  IconCalendarEvent,
  IconClipboardCheck,
  IconWorld,
} from "@tabler/icons";
import { Loader, Space, Text, Title } from "@mantine/core";
import { Teacher } from "../../models/teacher";

const Social = (social: any) => {
  const key: string = Object.keys(social.social)[0];
  const valueUrl: any = Object.values(social.social)[0];

  return (
    <li className="abc">
      <a href={`${valueUrl}`} target="_blank" rel="noreferrer">
        {key}
      </a>
    </li>
  );
};

const FindMeOn = ({ teacher }) => {
  if (!teacher.socials?.length) {
    return <></>;
  } else {
    return (
      <div className="teacherpage-findmeon">
        <Title order={2}>Find Me On:</Title>
        <ul>
          {teacher.socials?.map((social, index) => {
            return <Social key={index} social={social} />;
          })}
        </ul>
      </div>
    );
  }
};

const AboutMe = ({ teacher }) => {
  return (
    <div className="teacherpage-body">
      <Title order={2}>About Me</Title>
      <div className="teacherpage-description">
        <Text fz="lg">{teacher.aboutMe}</Text>
        <Space h="xl" />
        <Text fz="lg">{teacher.longDescription ?? ""}</Text>
      </div>
    </div>
  );
};

export const TeacherPage = () => {
  const [teacher, setTeacher] = useState(null);
  const [error, setError] = useState(false);
  const [picUrl, setPicUrl] = useState();
  const { state } = useLocation();
  const { teacherId } = useParams();
  const coffeDrinkerImage = require("../../assets/images/popart-sipping-coffee.jpg");

  const getImage = (teacher: Teacher) => {
    if (teacher.pictureStorageURI) {
      fetchFromStorage(teacher.pictureStorageURI, setPicUrl);
    }
  };

  useEffect(() => {
    if (state) {
      setTeacher(state);
      getImage(state);
    } else {
      fetchDocFromCollection(teacherId, "teachers", setTeacher).then(
        (teacherDate: Teacher) => {
          getImage(teacherDate);
        }
      );
    }
  }, []);

  return (
    <div>
      <div className="teacher-banner"></div>

      <div className="teacherpage-container">
        {teacher ? (
          <>
            <div className="teacher-wrapper">
              <img
                alt="teacher profile"
                src={picUrl ? picUrl : coffeDrinkerImage}
                className="teacherpage-image"
              />
              <div className="teacher-info-actions">
                <div className="teacher-info">
                  <Title order={1}>
                    {teacher.name}&nbsp;{teacher.lastname}
                  </Title>
                  <Text fz="xl" c="dimmed" tt="capitalize">
                    {teacher.outline}
                  </Text>
                </div>
                <div className="teacher-actions"></div>
              </div>
            </div>

            <Space h="xl" />
            <AboutMe teacher={teacher} />
            <FindMeOn teacher={teacher} />

            <div className="info-container">
              <div className="box-inline">
                <IconClipboardCheck /> &nbsp;&nbsp;
                <Title order={4}>
                  {teacher.classesCompleted ? teacher.classesCompleted : 0}{" "}
                  Classes completed
                </Title>
              </div>
              {teacher.livesIn && (
                <div className="box-inline">
                  <IconWorld /> &nbsp;&nbsp;
                  <Title order={4}>Lives in {teacher.livesIn}</Title>
                </div>
              )}
              {teacher.joinedInYear && (
                <div className="box-inline">
                  <IconCalendarEvent /> &nbsp;&nbsp;
                  <Title order={4}>Joined in {teacher.joinedInYear}</Title>
                </div>
              )}
            </div>
            <Space h="md" />
            <Title order={2}>Upcoming Classes</Title>
            <Text fz="lg">In Preparation</Text>

            <Space h="md" />
            <Title order={2}>Reviews</Title>
            <Text fz="lg">No reviews yet</Text>
          </>
        ) : (
          <>
            {!error ? (
              <Loader className="classpage-body" size="lg" variant="dots" />
            ) : (
              <div className="classpage-body">
                Sorry, this teacher has no profile page yet
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

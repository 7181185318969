import * as React from "react";
import "./CoursePage.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../states/AuthContext";
import { fetchDocFromCollection } from "../../helpers/utils";

// Mantine
import {
  IconArrowLeft,
  IconChevronRight,
  IconCurrencyEuro,
  IconCircleNumber1,
  IconClock,
  IconUsers,
  IconCalendarEvent,
} from "@tabler/icons";
import { Button, Loader, Rating, Space, Text, Title } from "@mantine/core";

export const CoursePage = () => {
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const { courseId } = useParams();

  const openInNewTab = (url: string) => {
    user
      ? window.open(url, "_blank", "noopener,noreferrer")
      : navigate("/login");
  };

  const handleTeacherRouting = (teacherId: string) => {
    teacherId &&
      navigate(`/teachers/${teacherId}`, {
        state: teacherId,
      });
  };

  useEffect(() => {
    if (state) {
      setCourse(state);
    } else {
      const fetchCourse = async () => {
        await fetchDocFromCollection(courseId, "classes", setCourse);
        setError(!!!course);
      };
      fetchCourse();
    }
  }, [courseId]);

  return (
    <div className="main">
      <div className="classpage-container">
        <div className="classpage-back-button">
          <Button
            color="dark"
            variant="subtle"
            onClick={() => navigate(-2)}
            leftIcon={<IconArrowLeft size={14} />}
          >
            Back
          </Button>
        </div>
        {course ? (
          <>
            <div className="classpage-header">
              <Title order={1} className="title">
                {course.outline}
              </Title>
              <Text fz="xl" c="dimmed">
                {course.classes === 1 && "One-Time Class"}
              </Text>
            </div>

            <Space h="xl" />
            <div className="classpage-body">
              <div className="classpage-description">
                <Text fz="lg">{course.description}</Text>
                <Space h="xl" />

                {course.extendedDescription && (
                  <Text fz="lg">{course.extendedDescription}</Text>
                )}
              </div>
              <img alt="" src={course.image} className="classpage-image" />
            </div>
            <Space h="xl" />
            <Text fz="lg" mb="sm">
              Author:
              {course.teacherId ? (
                <button
                  className="button-as-link"
                  onClick={() => handleTeacherRouting(course.teacherId)}
                >
                  {course.teacherName}&nbsp;{course.teacherLastname}
                </button>
              ) : (
                <>
                  {course.teacherName}&nbsp;{course.teacherLastname}
                </>
              )}
            </Text>
            {course.rating ? (
              <Rating value={course.rating} fractions={2} readOnly />
            ) : (
              <>
                <Rating value={course.rating} fractions={2} readOnly />
                <Text fz="lg" mb="sm">
                  No ratings yet!
                </Text>
              </>
            )}
            <Space h="md" />

            <div className="info-container">
              {/* This should be improved to include the following cases One Time, Recurring, X times per week */}
              {course.classes === 1 && (
                <div className="frequency box-inline">
                  <IconCircleNumber1 />
                  &nbsp;&nbsp;
                  <Title order={4}>One-Time Class</Title>
                </div>
              )}
              <div className="minutesClass box-inline">
                <IconClock />
                &nbsp;&nbsp;
                {course.minutesClass % 60 === 0 ? (
                  <Title order={4}>
                    {course.minutesClass / 60} hours per class
                  </Title>
                ) : (
                  <Title order={4}>
                    {course.minutesClass} minutes per class
                  </Title>
                )}
              </div>
              <div className="max-min-learners box-inline">
                <IconUsers /> &nbsp;&nbsp;
                <Title order={4}>
                  {course.minLearnersClass || 1}&nbsp;-&nbsp;
                  {course.maxLearnersClass || 10}
                  &nbsp;learners per class
                </Title>
              </div>
              <div className="availability box-inline">
                <IconCalendarEvent /> &nbsp;&nbsp;
                {course.schedule?.[0] ? (
                  <Title order={4}>
                    {new Date(course.schedule[0].seconds * 1000).toDateString()}
                    &nbsp;at&nbsp;
                    {new Date(
                      course.schedule[0].seconds * 1000
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </Title>
                ) : (
                  <Title order={4}>In Preparation</Title>
                )}
              </div>
            </div>
            <Space h="md" />
            <div className="price-container">
              <div className="price">
                <IconCurrencyEuro size={40} />
                <Title order={1}>{course.unitPrice}</Title>
              </div>

              <Text fz="lg" mb="sm">
                Total per learner
              </Text>
            </div>
            <Space h="md" />

            <Button
              radius="md"
              size="md"
              uppercase
              color="#1b4942"
              style={{ backgroundColor: "var(--color-main)" }}
              rightIcon={<IconChevronRight size={14} />}
              onClick={() => openInNewTab(course.paymentLink)}
            >
              Book Class
            </Button>
          </>
        ) : (
          <>
            {!error ? (
              <Loader className="classpage-body" size="lg" variant="dots" />
            ) : (
              <div className="classpage-body">
                Sorry, this course is going to be updated soon
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

import * as React from "react";
import "./CourseCard.css";
import { showDateTime } from "../../../helpers/utils";

// Mantine
import { Card, Text, Image, Title } from "@mantine/core";
// import { Course } from "../../../models/course";
// Change outline to title then fix type

// Fix type
export function CourseCard(props) {
  const { outline, image, schedule, author } = props;

  return (
    <Card
      withBorder
      shadow="lg"
      p="xl"
      component="a"
      href="#"
      className="course-card"
    >
      <Card.Section className="image-container">
        <Image
          src={image}
          height={160}
          fit="contain"
          alt={outline}
          withPlaceholder
          placeholder={outline}
        />
      </Card.Section>

      <Text size="lg" mt="md" color="dimmed">
        {showDateTime(schedule?.[0])}
      </Text>

      <Title order={4} className="card-title">
        {outline}
      </Title>

      <Title order={5} fz="lg" mb="sm">
        Author: {author}
      </Title>
    </Card>
  );
}

import * as React from "react";
import "./Main.css";
import { useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { fetchCollection } from "../../helpers/utils";

// Components
import { CoursesContext } from "../../states/CoursesContext";
import { CourseCard } from "../card/course/CourseCard";

// Mantine
import {
  Text,
  BackgroundImage,
  Box,
  Button,
  Center,
  Loader,
  Title,
} from "@mantine/core";

export default function Main() {
  const coffeDrinkerImage = require("../../assets/images/popart-sipping-coffee.jpg");
  const { courses, setCourses } = useContext(CoursesContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (courses.length === 0) {
      fetchCollection("classes", setCourses);
    }
  }, [courses.length, setCourses]);

  return (
    <>
      <div className="web-only">
        <div className="intro-banner ">
          <div className="intro-text-container">
            <h1 className="intro-title">
              Learn <br /> Improve <br />
              Grow
            </h1>

            <Title order={3} color="dimmed" weight="500" mb="md">
              Learn anything through live classes with the best teachers
            </Title>

            <Button
              radius="md"
              size="md"
              uppercase
              variant="outline"
              color="#0d1087"
            >
              <NavLink to="/courses" className="route-link">
                Browse Classes
              </NavLink>
            </Button>
          </div>
          <img
            alt=""
            src={coffeDrinkerImage}
            className="intro-image-container"
          />
        </div>
      </div>
      {/* Mobile view */}
      <Box mx="auto" mb={"xl"} className="mobile-only">
        <BackgroundImage src={coffeDrinkerImage} mb={"xl"}>
          <Center p="md" className="intro-title">
            Learn <br /> Improve <br />
            Grow
          </Center>
        </BackgroundImage>

        <Text
          align={"center"}
          size="xl"
          transform={"capitalize"}
          mb="xl"
          mx="sm"
          color="dimmed"
        >
          Learn anything through live classes with the best teachers
        </Text>
        <Center>
          <Button radius="md" size="xl" uppercase variant="outline">
            <NavLink to="/courses" className="route-link">
              Browse Classes
            </NavLink>
          </Button>
        </Center>
      </Box>

      <div className="cards-container">
        {courses.length !== 0 ? (
          courses.map((course: any) => (
            <div
              key={course.id}
              onClick={() =>
                navigate(`/courses/${course.id}`, { state: course })
              }
            >
              <CourseCard {...course} showStats={true} />
            </div>
          ))
        ) : (
          <Loader variant="dots" size="lg" className="loader-spacing" />
        )}
      </div>
    </>
  );
}

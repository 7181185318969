import * as React from "react";
import "./TeacherCard.css";
import { useEffect, useState } from "react";

// Mantine
import { Card, Text, Title, BackgroundImage, Badge } from "@mantine/core";
import { IconStar } from "@tabler/icons";
import { fetchFromStorage } from "../../../helpers/utils";

const starIcon = <IconStar size={13} className="icon-star" />;

export default function TeacherCard({ teacher }) {
  const coffeDrinkerImage = require("../../../assets/images/popart-sipping-coffee.jpg");
  const [picUrl, setPicUrl] = useState();

  useEffect(() => {
    if (teacher.pictureStorageURI) {
      fetchFromStorage(teacher.pictureStorageURI, setPicUrl);
    }
  }, [teacher.pictureStorageURI]);

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder className="teacher-card">
      <Card.Section>
        <BackgroundImage
          src={picUrl ? picUrl : coffeDrinkerImage}
          alt={`${teacher.name} ${teacher.lastname}`}
          className="image-container"
        >
          {teacher.rating > 0 && (
            <Badge
              className="badge"
              variant="filled"
              color="indigo"
              sx={{ paddingLeft: 3 }}
              leftSection={starIcon}
            >
              <Text fz="sm">{teacher.rating}</Text>
            </Badge>
          )}
        </BackgroundImage>
      </Card.Section>

      <Title order={4} mt="md" mb="xs" className="teacher-name">
        {teacher.name} {teacher.lastname}
      </Title>

      <Text size="sm" color="dimmed" className="teacher-outline">
        {teacher.outline}
      </Text>
    </Card>
  );
}

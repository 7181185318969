import * as React from "react";
import "./CreateCourseForm.css";
import { UserAuth } from "../../states/AuthContext";
import { Course, CourseInfo } from "../../models/course";
import { ComponentStatus } from "../../models/shared";
import { useState } from "react";

// Mantine
import {
  Box,
  Button,
  TextInput,
  Group,
  Textarea,
  NumberInput,
  Loader,
} from "@mantine/core";
import { useForm } from "@mantine/form";

// Firebase
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";

export function CreateCourseForm({ teacherData }) {
  const [componentStatus, setComponentStatus] =
    useState<ComponentStatus>("ready");
  const { user } = UserAuth();
  let uid = user?.uid;

  const handleSubmit = () => {
    const formValues: CourseInfo = form.values;
    saveData({ formValues });
  };

  const saveData = async (props: { formValues: CourseInfo }) => {
    let courseInfo: CourseInfo = props.formValues;
    // Should happen in the backend, also check if new user or user is editing
    // const teacherData: Course = {
    //   ...courseInfo,
    //   rating,
    // };
    setComponentStatus("loading");
    await addDoc(collection(db, "classes"), courseInfo).then(() =>
      setComponentStatus("ready")
    );
  };

  const form = useForm({
    initialValues: {
      teacherId: uid,
      teacherName: teacherData.name,
      teacherLastname: teacherData.lastname,
      author: `${teacherData.name} ${teacherData.lastname}`,
      classes: 1, // it should be called lessons
      classesWeek: 1, // lessons per week
      description: "",
      extendedDescription: "",
      // image: '',
      maxLearnersClass: 10,
      minLearnersClass: 1,
      // level: '', // beginner, intermediate, advanced ?
      minutesClass: 90,
      outline: "",
      // schedule: [],
      unitPrice: 1, // price per class or lesson
      paymentLink: "",
    },

    validate: {
      teacherName: (value) =>
        value.length > 1 ? null : "Please, add your name",
      teacherLastname: (value) =>
        value.length > 1 ? null : "Please, add your lastname",
      description: (value) =>
        value.length < 250 ? null : "Only 250 characters allowed",
      extendedDescription: (value) =>
        value.length < 1250 ? null : "Only 250 characters allowed",
    },
  });

  return (
    <Box mx="auto">
      {componentStatus === "ready" ? (
        <form onSubmit={form.onSubmit(handleSubmit)} className="form">
          <TextInput
            withAsterisk
            label="Course Title"
            placeholder="A Short Clear Title"
            {...form.getInputProps("outline")}
          />
          <Textarea
            label="Description"
            placeholder="Class Description"
            minRows="3"
            {...form.getInputProps("description")}
          />
          <Textarea
            label="More Information If Necessary"
            placeholder="More Information If Necessary"
            minRows="5"
            {...form.getInputProps("extendedDescription")}
          />
          <NumberInput
            placeholder="How Many Lessons In Total?"
            label="How Many Lessons In Total?"
            withAsterisk
            min={1}
            max={99}
            {...form.getInputProps("classes")}
          />
          <NumberInput
            placeholder="How Many Lessons Each Week?"
            label="How Many Lessons Each Week?"
            withAsterisk
            min={1}
            max={99}
            {...form.getInputProps("classesWeek")}
          />
          <NumberInput
            placeholder="Class Length In Minutes"
            label="Class Length In Minutes"
            withAsterisk
            min={10}
            max={180}
            {...form.getInputProps("minutesClass")}
          />
          <NumberInput
            placeholder="Minimum Number Of Students"
            label="Minimum Number Of Students"
            withAsterisk
            min={1}
            max={99}
            {...form.getInputProps("minLearnersClass")}
          />
          <NumberInput
            placeholder="Maximum Number Of Students"
            label="Maximum Number Of Students"
            min={1}
            {...form.getInputProps("maxLearnersClass")}
          />
          {/* <TextInput
              label="Level"
              placeholder="level according to scale TBD"
              {...form.getInputProps("level")}
            /> */}
          <NumberInput
            placeholder="Total Price Per Attendee"
            label="Total Price In EURO Per Attendee"
            min={1}
            // Lernao doesn't load on iOS
            // parser={(value) => value.replace(/\€\s?|(,*)/g, "")}
            // formatter={(value) =>
            //   !Number.isNaN(parseFloat(value))
            //     ? `€ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            //     : "€ "
            // }
            {...form.getInputProps("unitPrice")}
          />
          {/* Add schedule of lessons */}

          <Group position="right" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      ) : (
        <Loader size="lg" />
      )}
    </Box>
  );
}
